import { motion } from 'framer-motion';
import React from 'react';
import './App.css';
import { Col, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

function App() {
  const cardVariant = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} className="App-logo" alt="logo" />
        </header>
        <main className="App-content">
          <Routes>
            <Route exact path="/" element={
              <Container fluid>
                <Row className="justify-content-center">
                  <Col
                    as={motion.div}
                    variants={cardVariant}
                    initial="hidden"
                    animate="visible"
                    xs={12} sm={6} md={4} lg={4} xl={3}
                    className="Card text-gray-800 mb-4 p-6 rounded-lg force-right"
                  >
                    <div className="Row">
                      <img src={`${process.env.PUBLIC_URL}/app-logos/octik.png`} className="w-10 h-10 mr-4 app-logo-img-rounded" alt="placeholder" />
                      <h2 className="title">Octik</h2>
                    </div>
                    <p className="description">Easily check live metal market data in an elegant way!</p>
                    <div className="platform-container">
                      <table>
                        <tr>
                          <td>
                            <p className="description-child-links">
                              <a href="https://apps.apple.com/us/app/octik/id6502247252">iOS</a>
                            </p>
                          </td>
                          <td>
                            <p className="description-child-links">&nbsp;|&nbsp;</p>
                          </td>
                          <td>
                            <p className="description-child-links">
                              <a href="https://play.google.com/store/apps/details?id=com.diovoid.octik">Android</a>
                            </p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Col>

                  <Col
                    as={motion.div}
                    variants={cardVariant}
                    initial="hidden"
                    animate="visible"
                    xs={12} sm={6} md={4} lg={4} xl={3}
                    className="Card text-gray-800 mb-4 p-6 rounded-lg force-left"
                  >
                    <div className="Row">
                      <img src={`${process.env.PUBLIC_URL}/app-logos/pixel-filer.png`} className="w-10 h-10 mr-4 app-logo-img-rounded" alt="placeholder" />
                      <h2 className="title">Pixel Filer</h2>
                    </div>
                    <p className="description">Designed to export and build images into PDF's on the go!</p>
                    <div className="platform-container">
                      <table>
                        <tr>
                          <td>
                            <p className="description-child-links">iOS</p>
                          </td>
                          <td>
                            <p className="description-child-links">&nbsp;|&nbsp;</p>
                          </td>
                          <td>
                            <p className="description-child-links">Android</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </Col>
                </Row>
              </Container>
            } />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/" element={<App />} />
          </Routes>
        </main>
        <footer className="App-footer text-white p-4">
          <div className="footer-container">
            <div className="footer-links">
              <a href="mailto:contact@diovoid.com">Contact Us</a>
              &nbsp;|&nbsp;
              <Link to="/">Home</Link>
              &nbsp;|&nbsp;
              <Link to="/privacy">Privacy Policy</Link>
            </div>
            <div className="footer-text">
              © 2024 Diovoid LLC | Made with love in Texas
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
