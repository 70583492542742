import React from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {
  return (
    <div className="privacy">
    <h1>Privacy Policy</h1>
    <p>This page informs visitors regarding our policies on the collection, use, and disclosure of Personal Information for those who decide to use our Service.</p>

    <p>By choosing to use our Service, you agree to the collection and use of information in accordance with this policy. The Personal Information we collect is used to provide and improve the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>

    <h2>Information Collection and Use</h2>
    <p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your device’s Advertising Identifier and Location. The information that we request will be retained by us and used as described in this privacy policy.</p>

    <p>Our app uses third-party services that may collect information used to identify you.</p>

    <h2>Links to the privacy policies of third-party service providers used by the app</h2>
    <ul>
        <li className='li-services'><a href="#">Apple Services</a></li>
        <li className='li-services'><a href="#">Google Services</a></li>
    </ul>

    <h2>Log Data</h2>
    <p>We want to inform you that whenever you use our Service, in the event of an error in the app, we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device's Internet Protocol (IP) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>

    <h2>Cookies</h2>
    <p>Cookies are files with a small amount of data commonly used as anonymous unique identifiers. These are sent to your browser from the websites you visit and stored on your device's internal memory.</p>

    <p>Our Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

    <h2>Service Providers</h2>
    <p>We may employ third-party companies and individuals for the following reasons</p>
    <br/>
    <ul>
        <li>To facilitate our Service</li>
        <li>To provide the Service on our behalf</li>
        <li>To perform Service-related services</li>
        <li>To assist us in analyzing how our Service is used</li>
    </ul>
    <br/>
    <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

    <h2>Security</h2>
    <p>We value your trust in providing us your Personal Information, and we strive to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

    <h2>Links to Other Sites</h2>
    <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

    <h2>Children's Privacy</h2>
    <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we can take necessary actions.</p>

    <h2>Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

    <h2>Contact Us</h2>
    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>

    </div>
  );
}

export default PrivacyPolicy;
